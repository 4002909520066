import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Next-Gen SaaS Design Agency`,
    text: "Scalable SaaS Design Services",
    content: "Our SaaS Design Agency specializes in crafting user-centric SaaS solutions to improve product experience and drive business success. With creativity and pragmatism in our SaaS Design Services, we help businesses simplify their complex product and stay ahead in the dynamic market.",
    list: [
      'User-Centric Designs',
      'Simplified Product',
      'Cross Platform Compatibility'
    ]
  };
  const ourData = {
    title: "Client Feedback on Our SaaS Design Company",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Accelerate Growth with Our <span class='h1-span'>SaaS Design Agency</span>",
    content: [
      {
        title: "7+ Years of Experience",
        text: "We have been designing SaaS products for over 7+ years, offering exceptional digital experiences to help you stay ahead of the curve and deliver cutting-edge solutions.",
      },
      {
        title: "200+ Successful SaaS Projects",
        text: "With a portfolio of 200+ completed SaaS projects, we have a proven track record of transforming businesses through innovative and user-centric designs.",
      },
      {
        title: "99% Client Satisfaction Rate",
        text: "Our commitment to excellence is reflected in our 99% client satisfaction rate, as we consistently exceed expectations and deliver exceptional results.",
      },
      {
        title: "10+ Dedicated Designers",
        text: "Our team of 10+ highly skilled and specialized designers, each with unique expertise working across industries, work to bring your SaaS vision to life.",
      },
    ],
  };
  const commonContent1 = {
    title: "Simplify Complex Product With SaaS Design Company",
    content: [
      {
        title: "Proven SaaS Expertise",
        text: "Our SaaS design agency leverages industry experience to create tailored solutions that address your unique SaaS challenges and drive your business forward.",
      },
      {
        title: "User-Centric Design Approach",
        text: "We understand your users' needs, pain points, and behaviors to ensure your SaaS platform delivers an exceptional experience that resonates with your target audience.",
      },
      {
          title: "Scalable Solutions",
          text: "Whether launching a new SaaS product or optimizing an existing one, our SaaS design services are scalable and adaptable, ensuring smooth workflows.",
        },
        {
            title: "Collaborative Partnerships",
            text: "We collaborate with your team to align our SaaS design services with your business objectives and ensure seamless integration with your existing processes and systems.",
        },
        {
          title: "Measurable Performance Improvements",
          text: "Our data-driven strategies and rigorous testing enable us to deliver tangible improvements in key metrics like user engagement, conversion rates, and retention.",
        },
      {
        title: "Design Innovation and Differentiation",
        text: "Our SaaS design agency takes a unique and innovative approach to helping your SaaS platform stand out in a competitive market and keeping you ahead of industry tre",
      },
    ],
  };
  const Questions = [
    "What makes your SaaS design agency the right choice?",
    "What SaaS Design Services does your agency provide?",
    "Can you customize SaaS Products to meet our requirements?",
    "How much time does it take to design a SaaS product?"
  ];
  const faqData = [
    {
      para: [
        `SaaS design agency stands out for its innovative solutions, agile development processes, and client-centric approach.`,
        `We prioritize creative problem-solving, cutting-edge technology, and tailored support to ensure your project's success.`
      ],
    },
    {
      para: [
        `Our SaaS design company offers a comprehensive range of SaaS design services and is dedicated to delivering high-quality, user-centric solutions tailored to your specific needs.`,
      ],
      list: [
        `<span><h4 class="inline">SaaS UX Design:</h4></span> We craft intuitive, user-friendly interfaces optimized for engagement and satisfaction. Our designers specialize in user research, wireframing, and prototyping to ensure seamless interactions.`,
        `<span><h4 class="inline">SaaS MVP Design:</h4></span> We validate ideas with a well-designed Minimum Viable Product (MVP). Our designers create MVP designs that balance functionality and simplicity, facilitating rapid iteration based on user feedback.`,
        `<span><h4 class="inline">SaaS UI Design:</h4></span> We create visually appealing interfaces that reflect your brand identity and enhance engagement. Our SaaS design company focuses on typography, color schemes, and navigation to deliver impactful UI designs.`,
        `<span><h4 class="inline">SaaS Product Design:</h4></span> We translate your vision into a well-designed SaaS product that meets user needs and drives growth. Our end-to-end SaaS design services ensure that every aspect of your product aligns with your business objectives.`,
        `<span><h4 class="inline">SaaS Web Design:</h4></span> We showcase your SaaS offering with visually stunning and responsive web designs. Our designers create effective landing pages, marketing sites, and client portals that drive conversions and engagement.`,
        `<span><h4 class="inline">SaaS App Design:</h4></span> Our SaaS design agency delivers seamless mobile experiences with intuitive and visually appealing app designs. We specialize in native and cross-platform app design, prioritizing usability and performance.`
      ]
    },
    {
      para: [
        `Absolutely! Customization is at the core of our approach. We work closely with our clients to understand their unique needs and preferences, ensuring that the final SaaS product aligns perfectly with their requirements.`,
        `From feature enhancements to branding and integration, we're committed to delivering a tailored solution that exceeds expectations.`
      ],
    },
    {
      para: [
        `The time required to design a SaaS product can vary depending on the complexity of the project, its scope, and the specific requirements.`,
        `Our SaaS design process typically involves iterative phases, including discovery, design, development, testing, and refinement.`,
        `We strive to maintain efficient timelines without compromising quality, aiming to deliver exceptional results within mutually agreed-upon deadlines. Please <a href="/contact-us/" title="contact us">contact us</a> to discuss the timeline in detail.`
      ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What makes your SaaS design agency the right choice?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "SaaS design agency stands out for its innovative solutions, agile development processes, and client-centric approach. 

          We prioritize creative problem-solving, cutting-edge technology, and tailored support to ensure your project's success."
              }
            },{
              "@type": "Question",
              "name": "What SaaS Design Services does your agency provide?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our SaaS design company offers a comprehensive range of SaaS design services and is dedicated to delivering high-quality, user-centric solutions tailored to your specific needs.
          SaaS UX Design
          We craft intuitive, user-friendly interfaces optimized for engagement and satisfaction. Our designers specialize in user research, wireframing, and prototyping to ensure seamless interactions.
          SaaS MVP Design
          We validate ideas with a well-designed Minimum Viable Product (MVP). Our designers create MVP designs that balance functionality and simplicity, facilitating rapid iteration based on user feedback.
          SaaS UI Design
          We create visually appealing interfaces that reflect your brand identity and enhance engagement. Our SaaS design company focuses on typography, color schemes, and navigation to deliver impactful UI designs.
          SaaS Product Design
          We translate your vision into a well-designed SaaS product that meets user needs and drives growth. Our end-to-end SaaS design services ensure that every aspect of your product aligns with your business objectives.
          SaaS Web Design
          We showcase your SaaS offering with visually stunning and responsive web designs. Our designers create effective landing pages, marketing sites, and client portals that drive conversions and engagement.
          SaaS App Design
          Our SaaS design agency delivers seamless mobile experiences with intuitive and visually appealing app designs. We specialize in native and cross-platform app design, prioritizing usability and performance.
          SaaS Design Audit
          Our designers identify strengths, weaknesses, and areas for improvement in your SaaS design. Our SaaS Design Services include comprehensive audits by experienced professionals, which provide actionable insights to optimize user experience."
              }
            },{
              "@type": "Question",
              "name": "Can you customize SaaS Products to meet our requirements?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely! Customization is at the core of our approach. We work closely with our clients to understand their unique needs and preferences, ensuring that the final SaaS product aligns perfectly with their requirements. 

          From feature enhancements to branding and integration, we're committed to delivering a tailored solution that exceeds expectations."
              }
            },{
              "@type": "Question",
              "name": "How much time does it take to design a SaaS product?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The time required to design a SaaS product can vary depending on the complexity of the project, its scope, and the specific requirements. 

          Our SaaS design process typically involves iterative phases, including discovery, design, development, testing, and refinement. 

          We strive to maintain efficient timelines without compromising quality, aiming to deliver exceptional results within mutually agreed-upon deadlines. Please contact us to discuss the timeline in detail."
              }
            }]
          }                                    
        `}
      </script>
    </Helmet>
    <Layout hire="Next-Gen SaaS Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Next-Gen SaaS Design Agency" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} />
      </div>
    </Layout>
    {/* PRODUCT PAGE */}
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="SaaS Design Agency | Designing For SaaS"
    description="Our SaaS Design Agency simplifies complex products, crafts user-centric designs, and data-driven strategies to offer creative, scalable SaaS solutions."
  />
);
